import { EditCalendar } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import EfStyleSheet from "../../utils/style-helper";
import ManualAttendanceListItem from "./manual-attendance-list-item";

export default function EditAttendance({
  open,
  onClose,
  attendance,
  onSubmit,
  action1 = {
    text: "Close",
    onClick: () => onClose(),
  },
  action2,
}: {
  open: boolean;
  onClose: () => void;
  attendance: HbType.AttendanceResponseType;
  onSubmit: (attendance: HbType.AttendanceUpdateType) => void;
  action1?: {
    text: string;
    onClick: () => void;
  };
  action2?: {
    text: string;
    onClick: () => void;
  };
}) {
  const [attendanceType, setAttendanceType] = useState("");
  const [isDetentioned, setIsDetentioned] = useState(false);
  const [detentionReason, setDetentionReason] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setAttendanceType(attendance.status);
    setIsDetentioned(attendance.detentioned === "yes");
    setDetentionReason(attendance.det_reason);
  }, []);

  useEffect(() => {
    if (attendanceType === "absent" || attendanceType === "skipped") {
      setIsDetentioned(false);
      setDetentionReason("");
    }
  }, [attendanceType]);

  const styles = EfStyleSheet.create({
    colorFilter: {
      backgroundColor:
        (attendanceType === "present"
          ? Constants.colors.lightTheme.palette.success.main
          : attendanceType === "late"
          ? Constants.colors.lightTheme.palette.warning.main
          : Constants.colors.lightTheme.palette.error.main) + "10",
    },
    colorFilterFull: {
      backgroundColor:
        attendanceType === "present"
          ? Constants.colors.lightTheme.palette.success.main
          : attendanceType === "late"
          ? Constants.colors.lightTheme.palette.warning.main
          : attendanceType === "skipped"
          ? Constants.colors.lightTheme.palette.text.secondary
          : Constants.colors.lightTheme.palette.error.main,
    },
    root: {
      flexWrap: "wrap",
      mt: 1,
      mb: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 1,
      p: 1,
      borderRadius: 2,
    },
    attendanceAction: {
      flexGrow: 1,
    },
    switchButton: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      m: 0,
      p: 0,
    },
  });
  return (
    <Dialog
      //   fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        // width: fullScreen ? "100%" : "auto",
        backgroundColor:
          (attendanceType === "present"
            ? Constants.colors.lightTheme.palette.success.main
            : attendanceType === "late"
            ? Constants.colors.lightTheme.palette.warning.main
            : attendanceType === "skipped"
            ? Constants.colors.lightTheme.palette.text.secondary
            : Constants.colors.lightTheme.palette.error.main) + "40",
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={[
          styles.colorFilter,
          { display: "flex", alignItems: "center", gap: 1 },
        ]}
      >
        <EditCalendar />
        Update
      </DialogTitle>
      <DialogContent
        sx={[
          {
            width: "100%",
            minWidth: 200,
          },
          styles.colorFilter,
        ]}
      >
        <Box>
          <ManualAttendanceListItem attendance={attendance} onEdit={null} />

          <Box sx={styles.root}>
            <Typography>Change status</Typography>
            <Select
              sx={styles.attendanceAction}
              value={attendanceType}
              onChange={(e) => setAttendanceType(e.target.value)}
            >
              <MenuItem value="present">Present</MenuItem>
              <MenuItem value="late">Late</MenuItem>
              <MenuItem value="absent">Absent</MenuItem>
              <MenuItem value="skipped">Skipped</MenuItem>
            </Select>
            {/* {attendanceType !== ("absent" || "skipped") && (
              <Box>
                <Typography>Detention Status</Typography>
                <Button
                  sx={styles.switchButton}
                  onClick={() => {
                    console.log("detentioned changes");
                    setIsDetentioned((o) => !o);
                  }}
                >
                  <Typography fontWeight="bold">Send to detention</Typography>
                  <Switch
                    checked={isDetentioned}
                    onChange={(e) => setIsDetentioned(e.target.checked)}
                  />
                </Button>

                {isDetentioned && (
                  <Box
                    width="100%"
                    gap={1}
                    display="flex"
                    flexDirection="column"
                  >
                    <Typography>Why?</Typography>
                    <TextField
                      variant="outlined"
                      multiline
                      maxRows={4}
                      fullWidth
                      placeholder="Reason"
                      value={detentionReason}
                      onChange={(e) => setDetentionReason(e.target.value)}
                    />
                  </Box>
                )}
              </Box>
            )} */}
          </Box>
          <Button
            fullWidth
            variant="contained"
            sx={styles.colorFilterFull}
            onClick={() => {
              onSubmit({
                status: attendanceType as any,
                detentioned: isDetentioned ? "yes" : "no",
                det_from: "",
                det_to: "",
                det_reason: detentionReason,
                det_enter: "",
                det_exit: "",
                det_teacher_id: null as any,
                id: attendance.id,
                present_by: "teacher",
                query_time: "",
              });
            }}
          >
            Submit
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.colorFilter}>
        {action2 && (
          <Button autoFocus onClick={action2.onClick}>
            {action2.text}
          </Button>
        )}
        {action1 && (
          <Button autoFocus onClick={action1.onClick}>
            {action1.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
