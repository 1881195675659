import { HideSource, Login, Logout, QrCode } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import moment from "moment-timezone";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import EfStyleSheet from "../../utils/style-helper";
import Utils from "../../utils/utils";
import FallbackImageView from "../fallback-image-view";

export default function ManualDetentionListItem({
  attendance,
  onEdit,
  libyaTime = "",
}: {
  attendance: HbType.AttendanceResponseType;
  onEdit: ((attendance: HbType.AttendanceResponseType) => void) | null;
  libyaTime: string;
}) {
  const styles = EfStyleSheet.create({
    root: {
      flexWrap: "wrap",
      mt: 1,
      mb: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 1,
      p: 1,
      borderRadius: 2,
      backgroundColor:
        (attendance.status === "present"
          ? Constants.colors.lightTheme.palette.success.main
          : attendance.status === "late"
          ? Constants.colors.lightTheme.palette.warning.main
          : attendance.status === "skipped"
          ? Constants.colors.lightTheme.palette.text.secondary
          : Constants.colors.lightTheme.palette.error.main) + "10",
      "&:hover": {
        backgroundColor:
          (attendance.status === "present"
            ? Constants.colors.lightTheme.palette.success.main
            : attendance.status === "late"
            ? Constants.colors.lightTheme.palette.warning.main
            : attendance.status === "skipped"
            ? Constants.colors.lightTheme.palette.text.secondary
            : Constants.colors.lightTheme.palette.error.main) + "30",
      },
    },
    avatar: {
      backgroundColor:
        Constants.colors.lightTheme.palette.secondary.main + "80",
    },
    center: {
      flexGrow: 1,
    },
    action: {
      height: "100%",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    status: {
      width: "fit-content",
      padding: "2px 8px",
      borderRadius: 4,
      color: "white",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 10,
      backgroundColor:
        attendance.status === "present"
          ? Constants.colors.lightTheme.palette.success.main
          : attendance.status === "late"
          ? Constants.colors.lightTheme.palette.warning.main
          : attendance.status === "skipped"
          ? Constants.colors.lightTheme.palette.text.secondary
          : Constants.colors.lightTheme.palette.error.main,
    },
    detentioned: {
      width: "fit-content",
      padding: "2px 8px",
      borderRadius: 4,
      color: "white",
      fontWeight: "bold",
      textTransform: "capitalize",
      fontSize: 10,
      display: attendance.status === "absent" ? "none" : "flex",
      backgroundColor:
        attendance.det_enter === ""
          ? Constants.colors.lightTheme.palette.warning.main
          : attendance.det_enter === "skipped"
          ? "#bababa"
          : Constants.colors.lightTheme.palette.error.main,
    },
  });
  return (
    <Box sx={styles.root}>
      <Box>
        <Avatar sx={styles.avatar}>
          <FallbackImageView
            src={Utils.retrieveImageURL(attendance.student.profile_url)}
            fallback={Constants.images.student}
          />
        </Avatar>
      </Box>
      <Box sx={styles.center}>
        <Typography
          variant="h6"
          sx={{ color: Constants.colors.lightTheme.palette.primary.main }}
        >
          {attendance.student.first_name}{" "} {attendance.student.middle_name}{" "} {attendance.student.last_name}
          <span style={{ color: "red", fontSize: 22 }}>
            {Array.from(
              Array(
                moment(libyaTime, "YYYY-MM-DD hh:mm:A").diff(
                  moment(attendance.det_from, "YYYY-MM-DD hh:mm:A"),
                  "days"
                ) || 0
              )
            ).map(() => {
              return "*";
            })}
          </span>
          <span style={{ fontSize: 10 }}> - {attendance.det_from}</span>
        </Typography>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {attendance.present_by === "qr" && <QrCode sx={{ fontSize: 18 }} />}
          <Typography sx={styles.status}>{attendance.status}</Typography>
          <Typography sx={styles.detentioned}>
            {attendance.det_enter === ""
              ? "Queued"
              : attendance.det_enter === "skipped"
              ? "Skipped"
              : "Detentioned "}
          </Typography>
        </Box>
        <Typography
          sx={{
            wordWrap: "break-word",
            wordBreak: "break-all",
            maxLines: !onEdit ? 2 : 20,
          }}
        >
          {!onEdit
            ? attendance?.det_reason &&
              attendance.det_reason.substring(0, 26) + "..."
            : attendance.det_reason}
        </Typography>
      </Box>

      <Box sx={styles.action}>
        {onEdit && (
          <IconButton
            color="primary"
            size="large"
            onClick={() => {
              onEdit(attendance);
            }}
          >
            {attendance.det_enter === "" ? (
              <Login />
            ) : attendance.det_enter === "skipped" ? (
              <HideSource />
            ) : (
              <Logout />
            )}
            {/* <EditCalendar /> */}
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
