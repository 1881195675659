import { HideSource } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import EfStyleSheet from "../../utils/style-helper";
import ManualDetentionListItem from "./manual-detention-list-item";

export default function HideDetentionDetentioned({
  open,
  onClose,
  attendance,
  onSubmit,
  action1 = {
    text: "Close",
    onClick: () => onClose(),
  },
  action2,
  libyaTime = "",
}: {
  open: boolean;
  onClose: () => void;
  attendance: HbType.AttendanceResponseType;
  onSubmit: (action: "exit" | "skip") => void;
  action1?: {
    text: string;
    onClick: () => void;
  };
  action2?: {
    text: string;
    onClick: () => void;
  };
  libyaTime: string;
}) {
  const [detentionType, setDetentionType] = useState("");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [pendingTask, setPendingTask] = useState<"exit" | "skip" | null>(null);

  useEffect(() => {
    setDetentionType(attendance.det_enter ? "queued" : "detentioned");
  }, []);

  const styles = EfStyleSheet.create({
    colorFilter: {
      backgroundColor:
        (detentionType === "queued"
          ? Constants.colors.lightTheme.palette.warning.main
          : Constants.colors.lightTheme.palette.error.main) + "10",
    },
    colorFilterFull: {
      backgroundColor:
        detentionType === "queued"
          ? Constants.colors.lightTheme.palette.warning.main
          : Constants.colors.lightTheme.palette.error.main,
    },
    root: {
      flexWrap: "wrap",
      mt: 1,
      mb: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 1,
      p: 1,
      borderRadius: 2,
    },
    attendanceAction: {
      flexGrow: 1,
    },
    switchButton: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      m: 0,
      p: 0,
    },
  });
  return (
    <Dialog
      //   fullScreen={fullScreen}

      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        width: fullScreen ? "100%" : "auto",
        backgroundColor:
          (detentionType === "queued"
            ? Constants.colors.lightTheme.palette.warning.main
            : Constants.colors.lightTheme.palette.error.main) + "40",
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={[
          styles.colorFilter,
          { display: "flex", alignItems: "center", gap: 1 },
        ]}
      >
        <HideSource />
        Hide
      </DialogTitle>
      <DialogContent
        sx={[
          {
            width: "100%",
            minWidth: 200,
          },
          styles.colorFilter,
        ]}
      >
        <Box>
          <ManualDetentionListItem
            libyaTime={libyaTime}
            attendance={attendance}
            onEdit={null}
          />

          <Box>
            <Button
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => {
                onSubmit("skip");
              }}
            >
              Hide
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.colorFilter}>
        {action2 && (
          <Button autoFocus onClick={action2.onClick}>
            {action2.text}
          </Button>
        )}
        {action1 && (
          <Button autoFocus onClick={action1.onClick}>
            {action1.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
