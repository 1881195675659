import axios from "axios";
import { HbType } from "./ConstType";

//TODO: always prepare api from Api.handler.{functionName}()
// const endPointUrl = "http://2.2.2.103:8033";

const endPointUrl = "https://napi.ism.ly";

const Api = {
  // endPointUrl: "https://ismschoool.com/efortshub",
  endpoints: {
    //get
    uploads: endPointUrl,
    libyaTime: endPointUrl + "/lt",
    //post
    login: (type: string) => endPointUrl + "/auth/" + type,
    //get
    getSupervisor: (token: string) => endPointUrl + "/teacher/get-sup/" + token,
    //get
    validate: (token: string) =>
      endPointUrl + "/auth/validate/teacher/" + token,
    event: {
      //get
      getActive(token: string, eventId: number) {
        return endPointUrl + "/teacher/active-event/" + eventId + "/" + token;
      },
      //get
      getUpNext(token: string) {
        return endPointUrl + "/teacher/upnext/" + token;
      },
    },
    attendance: {
      //get
      getAll(token: string, eventId: number) {
        return endPointUrl + "/teacher/attendance/" + eventId + "/" + token;
      },
      //get
      getDetentions(token: string) {
        return endPointUrl + "/teacher/detentions/" + token;
      },
      //post
      udpate(token: string) {
        return endPointUrl + "/teacher/attendance/update/" + token;
      },
      //post
      updateDetention(token: string) {
        return endPointUrl + "/teacher/detention/update/" + token;
      },
    },
  },
  handler: {
    supervisor: {
      get(token: string) {
        return axios.get(Api.endpoints.getSupervisor(token));
      },
    },
    login(email: string, password: string, token: string, type: string) {
      return axios.post(Api.endpoints.login(type), {
        email: email,
        password: password,
        token: token,
      });
    },
    validate(loginToken: string) {
      return axios.get(Api.endpoints.validate(loginToken));
    },
    event: {
      getActive(token: string, eventId: number) {
        return axios.get(Api.endpoints.event.getActive(token, eventId));
      },
      getUpNext(token: string) {
        return axios.get(Api.endpoints.event.getUpNext(token));
      },
    },
    attendance: {
      getAll(token: string, eventId: number) {
        return axios.get(Api.endpoints.attendance.getAll(token, eventId));
      },
      update(attendance: HbType.AttendanceUpdateType, token: string) {
        return axios.post(Api.endpoints.attendance.udpate(token), attendance);
      },
      getDetentions(token: string) {
        return axios.get(Api.endpoints.attendance.getDetentions(token));
      },
      updateDetention(
        attendance: HbType.AttendanceDetentionUpdateType,
        token: string
      ) {
        return axios.post(
          Api.endpoints.attendance.updateDetention(token),
          attendance
        );
      },
    },
  },
};

export default Api;
