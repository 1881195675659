/* eslint-disable react-hooks/exhaustive-deps */
import { AddToQueue, AllOut, RemoveFromQueue } from "@mui/icons-material";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import EditDetentionDetentioned from "../../components/attendance/dialog-edit-detention-detentioned";
import EditDetentionQueued from "../../components/attendance/dialog-edit-detention-queued";
import HideDetentionDetentioned from "../../components/attendance/dialog-hide-detention-detentioned";
import ManualDetentionListItem from "../../components/attendance/manual-detention-list-item";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function Detentions() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [attendances, setAttendances] = useState<
    HbType.AttendanceResponseType[]
  >([]);
  const [queued, setQueued] = useState<HbType.AttendanceResponseType[]>([]);
  const [detentioned, setDetentioned] = useState<
    HbType.AttendanceResponseType[]
  >([]);
  const [attendanceInEditMode, setAttendanceInEditMode] =
    useState<HbType.AttendanceResponseType | null>(null);

  const [activeFragment, setActiveFragment] = useState<
    "queued" | "detentioned" | "all" | "skipped"
  >("queued");

  const [libyaTime, setLibyaTime] = useState("");

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const itemToShow = useMemo(() => {
    let md = [] as HbType.AttendanceResponseType[];
    if (activeFragment === "all") md = attendances;
    else if (activeFragment === "detentioned") md = detentioned;
    else if (activeFragment === "queued") md = queued;
    return md;
  }, [attendances, activeFragment]);

  useEffect(() => {
    axios
      .get(Api.endpoints.libyaTime)
      .then((res) => {
        setLibyaTime(res.data);
      })
      .catch((e) => {});
    const cancelInterval = setInterval(() => {
      axios
        .get(Api.endpoints.libyaTime)
        .then((res) => {
          setLibyaTime(res.data);
        })
        .catch((e) => {});
    }, 2 * 60 * 1000);

    const interval = setInterval(() => {
      functionHandler.getDetentions(true);
    }, 1000 * 60 * 2);
    return () => {
      clearInterval(interval);
      clearInterval(cancelInterval);
    };
  }, []);
  useEffect(() => {
    functionHandler.getDetentions(true);
  }, [activeFragment]);

  const functionHandler = {
    updateDetentions(action: "enter" | "skip-enter" | "exit" | "skip-exit") {
      setIsLoading(true);
      let detentionData: HbType.AttendanceDetentionUpdateType;

      if (attendanceInEditMode === null) return;

      detentionData = {
        type: action,
        id: attendanceInEditMode.id,
      };

      Api.handler.attendance
        .updateDetention(detentionData, loginToken)
        .then((res) => res.data as ResponseType)
        .then((res) => {
          if (res.status === "success") {
            setAttendanceInEditMode(null);
            functionHandler.getDetentions(false);
          } else {
            setError(res.message);
          }
        })
        .catch((e) => {
          setError("Error: " + e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    getDetentions(backgrounded: boolean) {
      backgrounded || setIsLoading(true);
      setError("");
      Api.handler.attendance
        .getDetentions(loginToken)
        .then((res) => {
          const data = res.data as ResponseType;
          console.log("response of detentions: ", data);
          if (data.status !== "success") {
            setError(
              "Error: " +
                (typeof data.message === "object"
                  ? JSON.stringify(data.message)
                  : data.message)
            );

            return;
          }

          const attnds = data.message as HbType.AttendanceResponseType[];

          setQueued(attnds.filter((a) => a.det_enter === ""));
          setDetentioned(
            attnds.filter(
              (a) => a.det_enter !== "" && a.det_enter !== "skipped"
            )
          );
          setAttendances(attnds);
        })
        .catch((e) => {
          console.log("error of detentions: ", e);
          setError("Error: " + e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        wordWrap: "break-word",
      }}
    >
      <Typography sx={{ color: "#f30", padding: 2, width: "100%" }}>
        {error}
      </Typography>

      {isLoading && <LoadingView />}

      {attendanceInEditMode &&
        (attendanceInEditMode.det_enter === "" ? (
          <EditDetentionQueued
            libyaTime={libyaTime}
            open={attendanceInEditMode !== null}
            attendance={attendanceInEditMode}
            onClose={() => {
              setAttendanceInEditMode(null);
            }}
            onSubmit={(action) => {
              if (action === "enter") {
                functionHandler.updateDetentions("enter");
              } else functionHandler.updateDetentions("skip-enter");
            }}
          />
        ) : attendanceInEditMode.det_enter === "skipped" ? (
          <HideDetentionDetentioned
            libyaTime={libyaTime}
            open={attendanceInEditMode !== null}
            attendance={attendanceInEditMode}
            onClose={() => {
              setAttendanceInEditMode(null);
            }}
            onSubmit={() => {
              functionHandler.updateDetentions("skip-exit");
            }}
          />
        ) : (
          <EditDetentionDetentioned
            libyaTime={libyaTime}
            open={attendanceInEditMode !== null}
            attendance={attendanceInEditMode}
            onClose={() => {
              setAttendanceInEditMode(null);
            }}
            onSubmit={(action) => {
              if (action === "exit") {
                functionHandler.updateDetentions("exit");
              } else functionHandler.updateDetentions("skip-exit");
            }}
          />
        ))}

      {itemToShow &&
        itemToShow.map &&
        itemToShow.map((a) => (
          <ManualDetentionListItem
            libyaTime={libyaTime}
            attendance={a}
            onEdit={(attendance) => {
              setAttendanceInEditMode(attendance);
            }}
            key={a.id}
          />
        ))}

      <BottomNavigation
        showLabels={true}
        value={activeFragment}
        onChange={(_, value) => setActiveFragment(value)}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <BottomNavigationAction
          icon={<AddToQueue />}
          label="Queued"
          value="queued"
        />
        <BottomNavigationAction
          icon={<RemoveFromQueue />}
          label="Detentioned"
          value="detentioned"
        />
        <BottomNavigationAction icon={<AllOut />} label="All" value="all" />
      </BottomNavigation>
    </Box>
  );
}
