/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowBack, Event, ImportExport, Info } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { load } from "react-cookies";
import EventStats from "../components/attendance/event-stats";
import ManualDetention from "../components/attendance/fg-manual-detention";
import LoadingView from "../components/loading-view";
import AtendanceFragment from "../fragments/active-event/fg-attendance-active-event";
import Api from "../utils/Api";
import { HbType, ResponseType } from "../utils/ConstType";
import Constants from "../utils/Constants";
import HbStyleSheet from "../utils/style-helper";

export default function ActiveEventScreen() {
  const [goBack, setGoback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [event, setEvent] = useState<HbType.EventResponseType>();
  const [currentLibyaTime, setCurrentLibyaTime] = useState("");
  const [endAt, setEndAt] = useState("");
  const [activeFragment, setActiveFragment] = useState<
    "attendance" | "info" | "behaviour"
  >("info");
  const [attendances, setAttendances] = useState<
    HbType.AttendanceResponseType[]
  >([]);

  const navigate = useNavigate();
  const param = useParams();

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );
  useEffect(() => {
    // alert(loginToken);

    if (loginToken) {
      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;

          if (apiResponse.status === "success") {
            setIsLoading(false);
            functionHandler.getEventDetails();
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      navigate(Constants.navigationStack.login);
    }
    return functionHandler.getCurrentLibyaTime();
  }, []);

  useEffect(() => {
    functionHandler.getAllAttendance();
  }, [activeFragment]);

  useEffect(() => {
    if (goBack) {
      navigate(Constants.navigationStack.home);
    }
  }, [goBack, navigate]);

  useEffect(() => {
    if (event && currentLibyaTime) {
      const timeLeft = moment
        .duration(
          moment(currentLibyaTime, "YYYY-MM-DD hh:mm:A").diff(
            moment(event.end_time, "hh:mm:A")
          )
        )
        .humanize();
      console.log("time left is: ", timeLeft);
      setEndAt(timeLeft);
    }
  }, [currentLibyaTime, event]);

  const functionHandler = {
    getAllAttendance() {
      Api.handler.attendance
        .getAll(loginToken, event?.id || -1)
        .then((r) => {
          console.log("response of attendance is: ", r.data);
          const res: ResponseType = r.data;
          const attendance = res.message as HbType.AttendanceResponseType[];
          setAttendances(attendance);
        })
        .catch((e) => {
          console.log("failed getting response of attendacne. reason: ", e);
        })
        .finally(() => {});
    },
    getCurrentLibyaTime: () => {
      axios
        .get(Api.endpoints.libyaTime)
        .then((res) => {
          setCurrentLibyaTime(res.data);
        })
        .catch((e) => {});
      const cancelInterval = setInterval(() => {
        axios
          .get(Api.endpoints.libyaTime)
          .then((res) => {
            setCurrentLibyaTime(res.data);
          })
          .catch((e) => {});
      }, 55 * 1000);

      return () => clearInterval(cancelInterval);
    },
    getEventDetails: () => {
      setError("");
      setIsLoading(true);
      console.log("getting event details of event: ", param.eventId);
      Api.handler.event
        .getActive(loginToken, Number(param.eventId))
        .then((res) => {
          console.log("event response : ", res.data);
          const r = res.data as ResponseType;
          const active = r.message.active as HbType.EventResponseType[];
          const next = r.message.next as HbType.EventResponseType[];
          if (active.length > 0) {
            setEvent(active[0]);
          }
        })
        .catch((e) => {
          console.log("error on getting event: ", e);
          setError(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  const styles = HbStyleSheet.create({
    toobar: {
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 1,
    },
    backButton: { color: Constants.colors.lightTheme.palette.text.onPrimary },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    topEndIndicator: {
      backgroundColor: Constants.colors.lightTheme.palette.warning.main + "30",
      border: `1px solid ${Constants.colors.lightTheme.palette.warning.main}60`,
      borderRadius: 4,
      m: 1,
      p: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      alignSelf: "center",
    },
  });

  const MyAppBar = () => {
    return (
      <AppBar position="sticky">
        <Toolbar sx={styles.toobar}>
          <IconButton
            sx={styles.backButton}
            onClick={() => {
              setGoback(true);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
            {event ? event.title : "Unknown"}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  };
  const TopEndTimeIndicator = () => (
    <Box sx={styles.topEndIndicator}>
      {/* <Typography>{currentLibyaTime}</Typography> */}

      <Typography>
        This feature won't be available after{" "}
        <b
          style={{
            color: Constants.colors.lightTheme.palette.error.main,
          }}
        >
          {endAt}
        </b>{" "}
        at{" "}
        <b
          style={{
            color: Constants.colors.lightTheme.palette.error.main,
          }}
        >
          {" "}
          {event?.end_time}{" "}
        </b>
        based on Libya Standard Time.
      </Typography>
    </Box>
  );

  return (
    <>
      {isLoading && <LoadingView />}
      <Box>
        <MyAppBar />
        <Container sx={styles.container}>
          <TopEndTimeIndicator />
          {event ? (
            activeFragment === "info" ? (
              <EventStats event={event as any} />
            ) : activeFragment === "attendance" ? (
              <AtendanceFragment event={event as any} />
            ) : (
              <Box sx={{ width: "100%" }}>
                <ManualDetention attendances={attendances} />
              </Box>
            )
          ) : (
            <CircularProgress />
          )}
        </Container>

        <BottomNavigation
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
          showLabels
          value={activeFragment}
          onChange={(event, newValue) => {
            setActiveFragment(newValue);
          }}
        >
          <BottomNavigationAction label="Info" value="info" icon={<Info />} />
          <BottomNavigationAction
            label="Attendance"
            value="attendance"
            icon={<Event />}
          />
          <BottomNavigationAction
            label="Behaviour"
            value="behaviour"
            icon={<ImportExport />}
          />
        </BottomNavigation>
      </Box>
    </>
  );
}
