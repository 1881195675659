import { useState } from "react";
import Constants from "../utils/Constants";
import { HBImageTint } from "./temp-ef-image-root";

export default function FallbackImageView({
  src,
  fallback,
  sx = { width: "100%", height: "100%" },
}: {
  src: any;
  fallback: any;
  sx?: React.CSSProperties;
}) {
  const [f, setfallback] = useState(null);

  return f ? (
    <HBImageTint
      src={f}
      alt={src}
      color={Constants.colors.lightTheme.palette.text.onPrimary}
      sx={sx}
    />
  ) : (
    <img
      style={sx}
      alt={src}
      src={src}
      onError={() => {
        setfallback(fallback);
      }}
    />
  );
}
