import { Warning, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { MD5 } from "crypto-js";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import HbStyleSheet from "../../utils/style-helper";

export default function QrCode({
  event,
  attendances,
}: {
  event: HbType.EventResponseType;
  attendances: HbType.AttendanceResponseType[];
}) {
  const [qrValue, setQrValue] = useState("");
  const [qrSize, setQrSize] = useState(0);

  useEffect(() => {
    const { teacherName, className, gradeName, subjectName, ...serverValue } =
      event;

    setQrValue(MD5(JSON.stringify(serverValue)).toString());
    setQrSize(window.innerWidth > window.innerHeight ? 50 : 100);
  }, []);

  //   console.log("my value is: ", mv);

  const styles = HbStyleSheet.create({
    fgSwitchWrapper: {
      backgroundColor:
        Constants.colors.lightTheme.palette.secondary.main + "20",
      border: `1px solid ${Constants.colors.lightTheme.palette.secondary.main}30`,
      borderRadius: 4,
      overflow: "hidden",
      width: "fit-content",
      m: 4,
      position: "fixed",
      bottom: 32,
      right: 0,
    },
  });

  if ((attendances?.length || 0) === 0) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
      >
        <Avatar>
          <Warning />
        </Avatar>
        <Typography>No Data Found!</Typography>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      pb={24}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <QRCode
        size={256}
        zoomAndPan="1px"
        style={{
          height: "auto",
          maxWidth: qrSize + "%",
          width: "100%",
          backgroundColor: "white",
        }}
        value={qrValue}
        viewBox={`0 0 256 256`}
      />
      <Box>
        <Box sx={styles.fgSwitchWrapper}>
          <Button onClick={() => setQrSize((o) => Math.max(15, o - 5))}>
            <ZoomOut />
          </Button>
          <Button onClick={() => setQrSize((o) => Math.min(100, o + 5))}>
            <ZoomIn />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
