import {
  CalendarViewDay,
  CalendarViewWeek,
  Edit,
  NextPlan,
  Note,
  Settings,
} from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import React, { useMemo, useState } from "react";
import { load } from "react-cookies";
import { useNavigate } from "react-router-dom";
import AccountActionTooltip from "../components/dashboard/account-action-tooltip";
import DashboardDrawer from "../components/dashboard/drawer";
import HeaderBar from "../components/header-bar";
import LoadingView from "../components/loading-view";
import Detentions from "../fragments/dashboard/fg-detentions";
import UpNext from "../fragments/dashboard/fg-up-next";
import Api from "../utils/Api";
import { HbType, ResponseType } from "../utils/ConstType";
import Constants from "../utils/Constants";
//cookie.load("login-type");

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [me, setMe] = useState<HbType.TeacherDataType>({
    id: -1,
    campus_id: -1,
    phone: "",
    profile_url: "",
    token: "",
    password: null,
    name: "N/A",
    email: "N/A",
  });
  const [currentSupervisor, setCurrentSupervisor] = useState(-99);
  const [activeFragment, setActiveFragment] = useState<
    | "home"
    | "my-calendar"
    | "student-calendar"
    | "edit-account"
    | "settings"
    | "detention"
  >("home");
  const [title, setTitle] = useState("Dashboard");

  console.log("me is: ", me);
  const isIAmSupervisor = useMemo(() => {
    return me.id === currentSupervisor;
  }, [me, currentSupervisor]);

  const drawerItem = [
    {
      groupTitle: "Campus area",
      items: [
        {
          title: "Up Next",
          onClick: () => {
            setActiveFragment("home");
          },
          iconComponent: <NextPlan />,
        },
        {
          title: "My Calendar",
          onClick: () => {
            setActiveFragment("my-calendar");
          },
          iconComponent: <CalendarViewDay />,
        },
        {
          title: isIAmSupervisor ? "Detention" : "Student Calendar",
          onClick: () => {
            setActiveFragment(
              isIAmSupervisor ? "detention" : "student-calendar"
            );
          },
          iconComponent: isIAmSupervisor ? <Note /> : <CalendarViewWeek />,
        },
      ],
    },
    {
      groupTitle: "Account",
      items: [
        {
          title: "Edit account",
          onClick: () => {
            setActiveFragment("edit-account");
          },
          iconComponent: <Edit />,
        },
        {
          title: "Settings",
          onClick: () => {
            setActiveFragment("settings");
          },
          iconComponent: <Settings />,
        },
      ],
    },
  ];

  const navigate = useNavigate();
  React.useEffect(() => {
    const loginToken = load(Constants.key.CookiesKey.loginToken);

    // alert(loginToken);

    if (loginToken) {
      Api.handler.supervisor
        .get(loginToken)
        .then((res) => {
          const data = res.data as ResponseType;
          if (data.status === "success") {
            setCurrentSupervisor(Number(String(data.message)));
          }
        })
        .catch((e) => {});

      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;

          if (apiResponse.status === "success") {
            console.log("valid token: ", apiResponse);
            setMe(apiResponse.message);
            setIsLoading(false);
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }

          // if (response.data) {
          //   setIsLoading(false);
          // } else {
          // }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      navigate(Constants.navigationStack.login);
    }
  }, []);

  return (
    <Box>
      {isLoading && <LoadingView />}
      <HeaderBar
        title={title}
        onMenuClick={() => {
          setIsDrawerOpen((o) => !o);
        }}
        onUserNameClick={() => setShowTooltip(true)}
        userName={me.name}
      />
      <DashboardDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        drawerItem={drawerItem}
        setTitle={setTitle}
      />
      {showTooltip && (
        <AccountActionTooltip user={me} onHide={() => setShowTooltip(false)} />
      )}
      <Container>
        {activeFragment === "home" ? (
          <UpNext />
        ) : activeFragment === "edit-account" ? (
          <Box>edit account</Box>
        ) : activeFragment === "settings" ? (
          <Box> settings</Box>
        ) : activeFragment === "detention" ? (
          <Detentions />
        ) : (
          <Box></Box>
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;
