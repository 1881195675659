import { Sort } from "@mui/icons-material";
import { Box, Button, Dialog, IconButton, MenuItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import ManualAttendance from "../../components/attendance/fg-manual-attendance";
import QrCode from "../../components/attendance/fg-qr-code";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function AtendanceFragment({
  event,
}: {
  event: HbType.EventResponseType;
}) {
  const [activeFragment, setActiveFragment] = useState<"qr" | "manual">("qr");
  const [attendances, setAttendances] = useState<
    HbType.AttendanceResponseType[]
  >([]);
  const [filterBy, setFilterBy] = useState<
    "none" | "absent" | "present" | "late" | "detentioned"
  >("none");

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    functionHandler.getAllAttendance();
  }, [activeFragment]);

  const functionHandler = {
    getAllAttendance() {
      Api.handler.attendance
        .getAll(loginToken, event.id)
        .then((r) => {
          console.log("response of attendance is: ", r.data);
          const res: ResponseType = r.data;
          const attendance = res.message as HbType.AttendanceResponseType[];
          setAttendances(attendance);
        })
        .catch((e) => {
          console.log("failed getting response of attendacne. reason: ", e);
        })
        .finally(() => {});
    },
  };

  const FilterDialog = () => {
    return (
      <Dialog maxWidth="sm" fullWidth  open={false}>
        {["none", "absent", "present", "late", "detentioned"].map((e) => {
          return (
            <MenuItem
              onClick={() => {}}
              key={e}
              sx={
                e === filterBy
                  ? {
                      backgroundColor:
                        Constants.colors.lightTheme.palette.success.main + "80",
                    }
                  : {}
              }
            >
              {e}
            </MenuItem>
          );
        })}

        {/* <MenuItem>
          "none" | "absent" | "present" | "late" | "detentioned"
        </MenuItem>
        <MenuItem>
          "none" | "absent" | "present" | "late" | "detentioned"
        </MenuItem>
        <MenuItem onClick={() => alert("clicked")}>
          "none" | "absent" | "present" | "late" | "detentioned"
        </MenuItem> */}
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <FilterDialog />
      <Box
        sx={{
          overflow: "hidden",
          mb: 4,
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor:
              Constants.colors.lightTheme.palette.secondary.main + "20",
            border: `1px solid ${Constants.colors.lightTheme.palette.secondary.main}30`,
            borderRadius: 4,
            overflow: "hidden",
            width: "fit-content",
          }}
        >
          <Button
            variant={activeFragment === "qr" ? "contained" : "text"}
            onClick={() => setActiveFragment("qr")}
          >
            QR Code
          </Button>
          <Button
            variant={activeFragment === "manual" ? "contained" : "text"}
            onClick={() => setActiveFragment("manual")}
          >
            Manual
          </Button>
        </Box>
        {activeFragment === "manual" && (
          <IconButton>
            <Sort />
          </IconButton>
        )}
      </Box>
      <Box width="100%">
        {activeFragment === "qr" ? (
          <QrCode event={event} attendances={attendances} />
        ) : activeFragment === "manual" ? (
          <ManualAttendance attendances={attendances} />
        ) : (
          <LoadingView />
        )}
      </Box>
    </Box>
  );
}
