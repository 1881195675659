import { Box } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import EditAttendance from "./dialog-edit-attendance";
import ManualAttendanceListItem from "./manual-attendance-list-item";

export default function ManualAttendance({
  attendances,
}: {
  attendances: HbType.AttendanceResponseType[];
}) {
  const [attendanceInEditMode, setAttendanceInEditMode] =
    useState<HbType.AttendanceResponseType | null>(null);
  const [a, setA] = useState<HbType.AttendanceResponseType[]>([]);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    setA(attendances);
  }, [attendances]);

  const functionHandler = {
    updateAttendance(attendance: HbType.AttendanceUpdateType) {
      console.log("attendance to update: ", attendance);
      Api.handler.attendance
        .update(attendance, loginToken)
        .then((r) => {
          console.log("response is: ", r);
          const res = r.data as ResponseType;
          if (res.status === "success") {
            setA((o) => {
              return o.map((x) => {
                return x.id !== attendance.id
                  ? x
                  : {
                      ...x,
                      ...attendance,
                      query_time: res.message,
                    };
              });
            });
            setAttendanceInEditMode(null);
          } else {
            alert("Failed to update: " + res.message);
          }
        })
        .catch((e) => {
          console.log("error: ", e);
          alert("error updating info: " + e);
        })
        .finally(() => {});
    },
  };

  const MemoizedAttendanceList = memo(() => {
    return (
      <Box>
        {a &&
          a.map &&
          a.map((ax) => (
            <ManualAttendanceListItem
              key={ax.id}
              attendance={ax}
              onEdit={(at) => setAttendanceInEditMode(at)}
            />
          ))}
      </Box>
    );
  });

  return (
    <Box sx={{pb:15}}>
      {attendanceInEditMode && (
        <EditAttendance
          attendance={attendanceInEditMode}
          open
          onSubmit={functionHandler.updateAttendance}
          onClose={() => setAttendanceInEditMode(null)}
        />
      )}

      <MemoizedAttendanceList />
      
    </Box>
  );
}
