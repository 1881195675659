import { Timer } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import momentTimeZone from "moment-timezone";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../../utils/Api";
import { HbType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";

export default function ActiveEvent({
  event,
}: {
  event: HbType.EventResponseType;
}) {
  const [endAt, setEndAt] = useState<string>("Today");

  event.days_of_week = Object.fromEntries(
    Object.entries(event.days_of_week).sort((a, b) => {
      const order = ["su", "mo", "tu", "we", "th", "fr", "sa"];
      return order.indexOf(a[0]) - order.indexOf(b[0]);
    })
  ) as any;
  const dbSpl = event.start_time.split(":");
  const dbEpl = event.end_time.split(":");

  event.start_time =
    dbSpl[0] +
    ":" +
    ((dbSpl[1]?.length || 0) === 1 ? "0" + dbSpl[1] : dbSpl[1]) +
    ":" +
    dbSpl[2];

  event.end_time =
    dbEpl[0] +
    ":" +
    ((dbEpl[1]?.length || 0) === 1 ? "0" + dbEpl[1] : dbEpl[1]) +
    ":" +
    dbEpl[2];

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (event.full_day_event === "no") {
      axios
        .get(Api.endpoints.libyaTime)
        .then((res) => {
          const timeLeft = momentTimeZone
            .duration(
              momentTimeZone(res.data, "YYYY-MM-DD hh:mm:A").diff(
                momentTimeZone(event.end_time, "hh:mm:A")
              )
            )
            .humanize();
          //   console.log("time left is: ", timeLeft);
          setEndAt(timeLeft);
        })
        .catch((e) => {});
      const cancelInterval = setInterval(() => {
        axios
          .get(Api.endpoints.libyaTime)
          .then((res) => {
            const timeLeft = momentTimeZone
              .duration(
                momentTimeZone(res.data, "YYYY-MM-DD hh:mm:A").diff(
                  momentTimeZone(event.end_time, "hh:mm:A")
                )
              )
              .humanize();
            //   console.log("time left is: ", timeLeft);
            setEndAt(timeLeft);
          })
          .catch((e) => {});
      }, 2 * 60 * 1000);

      return () => clearInterval(cancelInterval);
    }
  }, []);

  return (
    <Box
      p={0.5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Paper
        sx={{
          margin: 0.3,
          width: "100%",

          // border: "1px solid black",
          padding: "4px 8px",
          borderRadius: 2,
          backgroundColor: Constants.colors.lightTheme.palette.background.paper,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            // cursor:'pointer',
            backgroundColor: "#00000005",
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          gap={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="100%">
            {/* event details area  */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
              gap={2}
            >
              <Avatar
                sx={{
                  bgcolor: Constants.colors.lightTheme.palette.secondary.main,
                }}
              >
                <b>{event.subjectName.substring(0, 1)}</b>
              </Avatar>
              <Box
                display="flex"
                gap={0}
                alignItems="flex-start"
                flexDirection="column"
                flexWrap="wrap"
                width="100%"
              >
                <Typography
                  noWrap
                  fontSize={16}
                  sx={{ fontWeight: "bold" }}
                  variant="subtitle1"
                  color={Constants.colors.lightTheme.palette.text.primary}
                >
                  {event.subjectName}
                </Typography>
                <Typography
                  fontSize={18}
                  sx={{
                    fontWeight: "bold",
                    overflowWrap: "anywhere",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    width: "100%",
                  }}
                  variant="subtitle2"
                  color={Constants.colors.lightTheme.palette.primary.main}
                  fontWeight="bold"
                >
                  {event.title} fsdkfjsdklf
                </Typography>
                <Box
                  sx={{
                    fontWeight: "bold",
                    width: "100%",
                    flexWrap: "wrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 0.5,
                    fontSize: 12,
                  }}
                >
                  <span>For</span>
                  <span
                    style={{
                      marginLeft: 4,
                      marginRight: 4,
                      color:
                        Constants.colors.lightTheme.palette.text.onSecondary,
                      backgroundColor:
                        Constants.colors.lightTheme.palette.secondary.main,
                      padding: "2px 8px",
                      borderRadius: 16,
                    }}
                  >
                    {event.gradeName}
                  </span>
                  <span>in</span>
                  <span
                    style={{
                      color:
                        Constants.colors.lightTheme.palette.text.onSecondary,
                      backgroundColor:
                        Constants.colors.lightTheme.palette.secondary.main,
                      padding: "2px 8px",
                      marginLeft: 4,
                      marginRight: 4,
                      borderRadius: 16,
                    }}
                  >
                    {event.className}
                  </span>
                </Box>
              </Box>
              {isMobile || (
                <Box
                  height="100%"
                  ml={1}
                  mr={1}
                  minHeight="100%"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Box
                    p={2}
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography
                      color={Constants.colors.lightTheme.palette.primary.main}
                      variant="body1"
                      sx={{ fontWeight: "bold" }}
                    >
                      Ends in <span style={{ color: "red" }}>{endAt} </span>
                    </Typography>
                    <Timer color="secondary" />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          gap={0}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box width="100%">
            {/* event details area  */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                height="100%"
                paddingTop={1}
                minHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Typography
                  color={Constants.colors.lightTheme.palette.secondary.main}
                  fontSize={14}
                  sx={{
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                  }}
                >
                  {event.description}
                </Typography>
              </Box>
              {event.type === "once" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  paddingTop={1}
                  justifyContent={"center"}
                  gap={1}
                >
                  <Typography noWrap fontSize={10}>
                    at
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  paddingTop={1}
                  gap={1}
                >
                  <Typography noWrap fontSize={10}>
                    from
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                  <Typography noWrap fontSize={10}>
                    to
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.end_date}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* //event type area  */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              // mt="4px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                flexWrap="wrap"
                // mt="4px"
              >
                <Typography
                  noWrap
                  textTransform="uppercase"
                  bgcolor={
                    Constants.colors.lightTheme.palette.secondary.main + "f0"
                  }
                  color={Constants.colors.lightTheme.palette.text.onPrimary}
                  p="2px 6px"
                  mt={1}
                  mb={1}
                  borderRadius={4}
                  fontSize={10}
                  boxShadow="1px 2px 5px 2px #ddd"
                >
                  <b
                    style={{
                      color:
                        Constants.colors.lightTheme.palette.text.onSecondary,
                    }}
                  >
                    {event.type}
                  </b>{" "}
                  {/* event duration */}
                  {event.full_day_event === "no" &&
                    event.start_time + " - " + event.end_time}
                </Typography>

                {event.full_day_event === "yes" && (
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={
                      Constants.colors.lightTheme.palette.secondary.main + "f0"
                    }
                    color={Constants.colors.lightTheme.palette.text.onPrimary}
                    p="2px 8px"
                    m={1}
                    variant="h6"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    <b>FULL DAY EVENT</b>
                  </Typography>
                )}

                {/* event days */}

                {event.type === "monthly" ? (
                  event.days_of_month.map((day) => (
                    <Box
                      key={day}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Checkbox size="small" checked />
                      <Typography
                        noWrap
                        fontSize={12}
                        textTransform="uppercase"
                      >
                        {day}
                      </Typography>
                    </Box>
                  ))
                ) : event.type === "weekly" ? (
                  Object.keys(event.days_of_week).map((day) => (
                    <Box
                      key={day}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Checkbox
                        size="small"
                        checked={(event as any).days_of_week[day] === true}
                      />
                      <Typography
                        noWrap
                        fontSize={12}
                        textTransform="uppercase"
                      >
                        {day}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Box />
                )}
              </Box>
            </Box>

            {isMobile && (
              <Box
                height="100%"
                minHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  flexGrow={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    color={Constants.colors.lightTheme.palette.primary.main}
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    Ends in <span style={{ color: "red" }}>{endAt} </span>
                  </Typography>
                  <Timer color="secondary" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            sx={{ m: 2 }}
            variant="contained"
            color="success"
            onClick={() => {
              navigate(
                Constants.navigationStack.attendance.replace(
                  ":eventId",
                  event.id + ""
                )
              );
            }}
          >
            Enter
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
